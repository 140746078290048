import React, { FC } from 'react';
import { useLocation } from 'react-router-dom';
import { useLoadTrips } from 'customHooks/useLoadTrips';
import { AccountHeaderContainer } from './style';

const AccountHeader: FC = () => {

  const location = useLocation();
  const { trips } = useLoadTrips();
  let headerTitle = '';
  let shwoHeaderTitle = true;

  switch (true) {
    case location.pathname==='/account/dashboard':
      headerTitle = 'Dashboard';
      break;
    case location.pathname==='/account/personalDetails':
      headerTitle = 'Personal Details';
      break;
    case location.pathname==='/account/email':
      headerTitle = 'Personal Details';
      break;  
    case location.pathname==='/account/phoneNumber':
      headerTitle = 'Personal Details';
      break;  
    case location.pathname==='/account/bookings':
      headerTitle = 'Bookings';
      break;
      case location.pathname==='/account/CancelGroupBooking':
        headerTitle = 'Cancel Booking';
        break;
    case location.pathname.split('/').length===4:
      
      const bookings = trips[location.pathname.split('/')[3]]?.bookings || [];
      const displayedCodes = bookings.length > 3 
        ? [...bookings.slice(0, 3).map(booking => booking.reservationCode), '...'] 
      : bookings.map(booking => booking.reservationCode);

      headerTitle = `Booking ${displayedCodes.join(', ')}`;
      break;
    case location.pathname.startsWith('/account/bookings/addGuestInfo/'):
      headerTitle = 'Add Guest Information';
      break;
    case location.pathname.startsWith('/account/bookings/addOns/'):
      headerTitle = 'Add-ons';
      break;
    case location.pathname.startsWith('/account/bookings/modifyDates/'):
      headerTitle = 'Modify Dates'
      break;   
    case location.pathname==='/account/billing':
      headerTitle = 'Billing';
      break;  
    case location.pathname==='/account/security':
      headerTitle = 'Security';
      shwoHeaderTitle = false;
      break;    
    case location.pathname==='/help':
      headerTitle = 'Help';
      break;
    default:
      headerTitle = 'Account';
  }

  return (
    <AccountHeaderContainer>
      {location.pathname==='/account/dashboard'? (
        <>
          <h1>Account</h1>
          {/* <h3>Customize Your Account Settings</h3> */}
        </>
      ): (location.pathname.split('/').length===4 || location.pathname.startsWith('/account/bookings/addGuestInfo'))? (
        <>
          <div className="accountLink">
            <a href='/account'>Account</a>
            <span>{'\u00A0\u00A0\u00A0>\u00A0\u00A0\u00A0'}</span>
            <a href='/account/bookings'>Bookings</a>
            <span>{'\u00A0\u00A0\u00A0>\u00A0\u00A0\u00A0'}</span>
            <span>{headerTitle}</span>
          </div>
          {shwoHeaderTitle?(<h2>{headerTitle}</h2>):(null)}
        </>
      ): location.pathname.split('/').length===5? (
        <>
          <div className="accountLink">
            <a href='/account'>Account</a>
            <span>{'\u00A0\u00A0\u00A0>\u00A0\u00A0\u00A0'}</span>
            <a href='/account/bookings'>Bookings</a>
            <span>{'\u00A0\u00A0\u00A0>\u00A0\u00A0\u00A0'}</span>
            <a href={`/account/bookings/${location.pathname.split('/')[4]}`}>
              Booking {trips[location.pathname.split('/')[4]]?.bookings[0]?.reservationCode}
            </a>
            <span>{'\u00A0\u00A0\u00A0>\u00A0\u00A0\u00A0'}</span>
            <span>{headerTitle}</span>
          </div>
          {shwoHeaderTitle?(<h2>{headerTitle}</h2>):(null)}
        </>
      ):(
        <>
          <div className="accountLink">
            <a href='/account'>Account</a>
            <span>{'\u00A0\u00A0\u00A0>\u00A0\u00A0\u00A0'}</span>
            <span>{headerTitle}</span>
          </div>
          {shwoHeaderTitle?(<h2>{headerTitle}</h2>):(null)}
        </>
      )}
    </AccountHeaderContainer>
  );
};

export default AccountHeader;
