import React from "react";
import { NumberInputWithButtonsContainer } from "./styles";
import FormInputField from "components/FormInputField";
import StyledButton from "components/Button";

type NumberInputWithButtonsProps = {
  min: number; // Minimum value allowed
  max: number; // Maximum value allowed
  value: number; // Current value of the input
  onChange: (newValue: number) => void; // Callback function triggered on value change
  errorMessage?: string; // Optional error message to display
};

export const NumberInputWithButtons: React.FC<NumberInputWithButtonsProps> = ({
  min,
  max,
  value,
  onChange,
  errorMessage,
}) => {
  // Handles decrement button click
  const handleDecrement = () => {
    if (value > min) {
      onChange(value - 1);
    }
  };

  // Handles increment button click
  const handleIncrement = () => {
    if (value < max) {
      onChange(value + 1);
    }
  };

  // Handles direct input changes
  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const newValue = parseInt(e.target.value, 10);
    if (!isNaN(newValue) && newValue >= min && newValue <= max) {
      onChange(newValue);
    }
  };

  return (
    <NumberInputWithButtonsContainer>
      {/* Decrement Button */}
      <StyledButton
        width="48px"
        height="48px"
        disabled={value <= min} // Disable if value is at minimum
        onClick={handleDecrement}
      >-</StyledButton>
      {/* Input Field */}
      <FormInputField
        className="inputField"
        label=""
        isRequired={false}
        type="text"
        error={!!errorMessage}
        errorMessage={errorMessage}
        inputHeight="48px"
        width="48px"
        marginBottom="0"
        value={value.toString()}
        maxLength={1} // Limit input length to 3 digits
        onChange={handleInputChange}
      />

      {/* Increment Button */}
      <StyledButton
        width="48px"
        height="48px"
        disabled={value >= max} // Disable if value is at maximum
        onClick={handleIncrement}
      >+</StyledButton>
    </NumberInputWithButtonsContainer>
  );
};

export default NumberInputWithButtons;