import styled from 'styled-components';
import { colors } from 'constants/colors';
import { BasicSectionContainer } from '../../../../../style';
import flight2 from 'assets/images/flight2.svg';

export const AccountContentContainer = styled(BasicSectionContainer)`

  min-width: 260px;
  /* -webkit-box-shadow: 0px 1px 3px 0px ${colors.accounMenuBorderShadowColor};
  -moz-box-shadow: 0px 1px 3px 0px ${colors.accounMenuBorderShadowColor};
  box-shadow: 0px 1px 3px 0px ${colors.accounMenuBorderShadowColor};
  border: 1px solid ${colors.accounBorderColor}; */

  .banner {
    margin-bottom: 32px;
  }

  .accountBody {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;

    .noBooking {
      width: 88%;
      height: 453px;
      background-image: url(${flight2});
      background-size: contain;
      background-position: center;
      background-repeat: no-repeat;
      margin: 5%;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;

      .content {
        width: 394px;
        height: 139px;
        font-size: 24px;
        font-weight: 600;
        color: ${colors.noBookingText};
        text-align: center;
        padding-top: 20px;
      }
    }

    .cardContent {
      padding: 32px;
      display: flex;
      flex-direction: column;
      align-items: stretch;
      align-content: stretch;
      flex: 1;
      .bookingTableHeader {
        justify-items: stretch;
        width: 100%;
        display: grid;
        grid-template-columns: 108fr 104fr 106fr 215fr 121fr 137fr;
        font-size: 16px;
        font-weight: 600;
        color: ${colors.black};
        padding: 20px 0;
        border-bottom: 1px solid ${colors.black};
        border-top: 1px solid ${colors.black};
        flex: 1;
        .status, .reservationCode, .rooms, .dates, .total, .button {
          text-align: left;
          font-size: 16px;
          font-weight: 600;
          line-height: 21px;
          padding-right: 10px;
        }
      }

      .bookingTableContent {
        display: grid;
        grid-template-columns: 1fr;

        .trip {
          display: grid;
          grid-template-columns: 108fr 104fr 106fr 215fr 121fr 137fr;
          padding: 22px 0;
          border-bottom: 1px solid ${colors.borderGrey2};

          .status, .reservationCode, .rooms, .dates, .total{
            text-align: left;
            padding-right: 10px;
          }
          .dates {
            display: flex;
            flex-direction: column;
            gap: 14px;
          }
          .button {
            text-align: left;
            padding-right: 0;
          }

          .dates {
            svg {
              margin-right: 8px;
            }
          }



          .button {
            display: flex;
            justify-content: flex-end;
          }
        }
      }

      .description {
        font-size: 16px;
        color: ${colors.black};
      }

      .passwordContainer {
        position: relative;
        width: 45%;
        margin: 32px 0;

        .oldPasswordLock, .oldPasswordUnlock {
          position: absolute;
          top: 45px;
          right: 20px;
          cursor: pointer;
        }

        .newPasswordLock, .newPasswordUnlock {
          position: absolute;
          top: 142px;
          right: 20px;
          cursor: pointer;
        }
      }

      .buttonContainer {
        position: relative;

        svg {
          position: absolute;
          top: -85px;
          left: 150px;
        }
      }
    }
  }
`;
