import styled from 'styled-components';
import { colors } from 'constants/colors';
import { styleSizes } from "constants/styleSizes";

type HintContainerProps = {

    width?: string;
    arrowPosition?: string;
    positionBottom?: string;
    positionLeft?: string;

    widthTablet?: string;
    arrowPositionTablet?: string;
    positionBottomTablet?: string;
    positionLeftTablet?: string;
  
  
    widthMini?: string;
    arrowPositionMini?: string;
    positionBottomMini?: string;
    positionLeftMini?: string;
  
    widthMobile?: string;
    arrowPositionMobile?: string;
    positionBottomMobile?: string;
    positionLeftMobile?: string;

  };

export const HintContainer = styled.div<HintContainerProps>`
      padding: 16px;
      border: 1px solid ${colors.hoverBlue};

      width: ${(props) => (props.width? props.width: '500')}px;
      bottom: ${(props) => (props.positionBottom? props.positionBottom: '88')}px;
      left: ${(props) => (props.positionLeft? props.positionLeft: '200')}px;

      @media (max-width: ${styleSizes.tabletBreakpoint}) {
        width: ${(props) => (props.widthTablet? props.widthTablet: '500')}px;
        bottom: ${(props) => (props.positionBottomTablet? props.positionBottomTablet: '88')}px;
        left: ${(props) => (props.positionLeftTablet? props.positionLeftTablet: '200')}px;
    }
      @media (max-width: ${styleSizes.miniBreakpoint}) {
        width: ${(props) => (props.widthMini? props.widthMini: '500')}px;
        bottom: ${(props) => (props.positionBottomMini? props.positionBottomMini: '88')}px;
        left: ${(props) => (props.positionLeftMini? props.positionLeftMini: '200')}px;
      }
      @media (max-width: ${styleSizes.mobileBreakpoint}) {
        width: ${(props) => (props.widthMobile? props.widthMobile: '500')}px;
        bottom: ${(props) => (props.positionBottomMobile? props.positionBottomMobile: '88')}px;
        left: ${(props) => (props.positionLeftMobile? props.positionLeftMobile: '200')}px;
      }

      -webkit-box-shadow: 0px 1px 3px 0px ${colors.accounMenuBorderShadowColor};
      -moz-box-shadow: 0px 1px 3px 0px ${colors.accounMenuBorderShadowColor};
      box-shadow: 0px 1px 3px 0px ${colors.accounMenuBorderShadowColor};
      display: flex;
      flex-direction: column;
      gap: 8px;
      z-index: 10;
      margin-bottom: 20px;
      position: absolute;
      background-color: ${colors.white};


      .header {
        font-size: 16px;
        font-weight: 600;
      }
      .message {
        font-size: 16px;
        font-weight: 400;
      }
      .hintArrowDownZone {
        position: relative;
        overflow: visible;
      }

    ::after {
      content: ""; /* Required for pseudo-elements */
      position: absolute; /* Position relative to the div */
      left: ${(props) => (props.arrowPosition? props.arrowPosition: '20')}px; /* Arrow position */

      @media (max-width: ${styleSizes.tabletBreakpoint}) {
        left: ${(props) => (props.arrowPositionTablet? props.arrowPositionTablet: '20')}px; /* Arrow position */
      }
      @media (max-width: ${styleSizes.miniBreakpoint}) {
        left: ${(props) => (props.arrowPositionMini? props.arrowPositionMini: '20')}px; /* Arrow position */
      }
      @media (max-width: ${styleSizes.mobileBreakpoint}) {
        left: ${(props) => (props.arrowPositionMobile? props.arrowPositionMobile: '20')}px; /* Arrow position */
      }


      bottom: -10px; /* Position below the div */
      transform: translateX(-50%); /* Center the arrow */
      width: 0;
      height: 0;
      border-left: 12px solid transparent; /* Left part of the arrow */
      border-right: 12px solid transparent; /* Right part of the arrow */
      border-top: 15px solid #fff; /* Arrow fill color (white) */
      
    }

    ::before {
      content: ""; /* Required for pseudo-elements */
      position: absolute; /* Position relative to the div */
      left: ${(props) => (props.arrowPosition? props.arrowPosition: '20')}px; /* Arrow position */

      @media (max-width: ${styleSizes.tabletBreakpoint}) {
        left: ${(props) => (props.arrowPositionTablet? props.arrowPositionTablet: '20')}px; /* Arrow position */
      }
      @media (max-width: ${styleSizes.miniBreakpoint}) {
        left: ${(props) => (props.arrowPositionMini? props.arrowPositionMini: '20')}px; /* Arrow position */
      }
      @media (max-width: ${styleSizes.mobileBreakpoint}) {
        left: ${(props) => (props.arrowPositionMobile? props.arrowPositionMobile: '20')}px; /* Arrow position */
      }

      bottom: -12px; /* Position directly below the outer arrow */
      transform: translateX(-50%); /* Center the inner arrow */
      width: 0;
      height: 0;
      border-left: 10px solid transparent; /* Left part of the inner arrow */
      border-right: 10px solid transparent; /* Right part of the inner arrow */
      border-top: 12px solid rgb(3, 141, 227); /* Inner arrow color */
    }
`;

