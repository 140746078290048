import React, { FC, useState } from 'react'
import { useHistory } from 'react-router-dom'
import Card from 'components/Card'
import FormInputField from 'components/FormInputField'
import Checkbox from 'components/Checkbox'
import StyledButton from 'components/Button';
import PaymentToolTip from './PaymentToolTip';
import { useRecoilValue, useRecoilState } from 'recoil'
import { dateRangeState, roomQuantityState, guestQuantityState } from 'states/SearchBarStates/atoms';
import { 
  selectedRooms,
  couponState,
  couponSuccessState,
  ratePlanChoice,
  termsOfServiceState,
  termsOfServiceErrorState,
} from 'states/CheckOutStates/atoms';
import { useGetBookingAddOns } from 'customHooks/useGetBookingAddOns';
import { ADVANCED, ADVANCED_RATE } from 'constants/ratePlanId';
import { dateDiff } from 'utils/util';
import { addOnOptionsState } from 'states/OptionStates/atoms';
import { useLazyQuery } from '@apollo/client';
import { APPLY_COUPON } from 'graphql/service';
import { BookingSummaryContainer } from './style';
import moment from 'moment';
import { HST_RATE, MAT_RATE } from 'constants/constant';
import { PriceQuestionIcon } from 'assets/icons';
import { colors } from 'constants/colors';

const BookingSummary: FC<{handleCheckOut: ()=> void, loading: boolean}> = ({handleCheckOut, loading}) => {

  const history = useHistory()
  
  const persistSelectedRooms = useRecoilValue(selectedRooms)
  const roomQuantity = useRecoilValue(roomQuantityState)
  const guestQuantity = useRecoilValue(guestQuantityState)
  const dateRange = useRecoilValue(dateRangeState)
  // customer selected addOns
  const addOnOptions = useRecoilValue(addOnOptionsState);
  const ratePlanId = useRecoilValue(ratePlanChoice)
  // all addOns query from server
  const bookingAddOns = useGetBookingAddOns()
  
  const [termsOfServiceError, setTermsOfServiceError] = useRecoilState(termsOfServiceErrorState)
  const [termsOfServiceChecked, setTermsOfServiceChecked] = useRecoilState(termsOfServiceState)

  const [coupon, setCoupon] = useRecoilState(couponState)
  const [couponInput, setCouponInput] = useState(coupon.couponCode || '')
  const [couponError, setCouponError] = useState(false)
  const [couponSuccess, setCouponSuccess] = useRecoilState(couponSuccessState)

  const successMessage = 'Success! Coupon code applied.'
  const errorMessage = 'Coupon code is invalid'

  const [applyCoupon] = useLazyQuery(APPLY_COUPON, {
    onCompleted: (couponQueryData) => {
      if(couponQueryData.couponByCode?.isActive) {
        setCouponError(false)
        setCouponSuccess(true)
        setCoupon(couponQueryData.couponByCode)
        localStorage.setItem("coupon", JSON.stringify(couponQueryData.couponByCode))
      } else {
        setCouponError(true)
        setCouponSuccess(false)
        setCouponInput('')
      }
    },
    onError: (error) => {
      setCouponError(true)
      setCouponSuccess(false)
      setCouponInput('')
    }
  })

  const nights = dateDiff(new Date(dateRange[0]), new Date(dateRange[1]))
  const roomFee = (persistSelectedRooms.pricePerNight || 0) * nights * roomQuantity
  const addOnFee = bookingAddOns.reduce((acc, addOn) => {
    if (addOnOptions.includes(addOn.addOnId)) {
      if(addOn.isOneTimePriced) {
        return acc + (addOn.pricePerNight || 0) * roomQuantity
      } else {
        return acc + (addOn.pricePerNight || 0) * nights * roomQuantity
      }
    }
    return acc
  }
  , 0)

  const couponDiscount = coupon.rateType==='FIXED_RATE'? (coupon.discount || 0): (coupon.discount || 0) / 100 * roomFee
  const ratePlanDiscount = ratePlanId === ADVANCED? coupon.rateType==='FIXED_RATE'? (roomFee - (coupon.discount || 0)) * (1 - ADVANCED_RATE): roomFee * (1 - (coupon.discount || 0) / 100) * (1 - ADVANCED_RATE): 0
  const discountedRoomFee = coupon.rateType==='FIXED_RATE'? (roomFee - (coupon.discount || 0)) * (ratePlanId === ADVANCED? ADVANCED_RATE: 1): roomFee * (1 - (coupon.discount || 0) / 100) * (ratePlanId === ADVANCED? ADVANCED_RATE: 1)
  const subtotal = discountedRoomFee + addOnFee
  const MAT = discountedRoomFee * MAT_RATE
  const HST = (subtotal + MAT) * HST_RATE
  const total = subtotal + MAT + HST

  const handleCouponApply = () => {
    if (couponInput.length) {
      applyCoupon({
        variables: {
          couponCode: couponInput.toUpperCase()
        }
      })
    }
  }

  const addOnPricesArray = bookingAddOns.map((addOn) => {
    if(addOnOptions.includes(addOn.addOnId)) {
      return (
        <div className="breakDownRow" key={addOn.addOnId}>
          <div className="breakDownLabel">
          {addOn.label}
          </div>
          <div className="breakDownValue">
            ${(addOn.isOneTimePriced? addOn.pricePerNight * roomQuantity: addOn.pricePerNight * nights * roomQuantity).toFixed(2)}
          </div>
        </div>
      )
    }
    return null
  })

  return (
    <BookingSummaryContainer>
      <Card>
        <div className="title">
          Book your stay with us
        </div>
        <div className="row">
          <div className="infoRow dates">
            <div className="infoLabel">
              DATES
            </div>
            <div className="infoValue">
              {moment(dateRange[0]).format('ddd, DD MMM')} - 
              {moment(dateRange[1]).format('ddd, DD MMM')}
            </div>
          </div>
          <div className="infoRow quantity">
            <div className="infoLabel">
              ROOMS / GUESTS
            </div>
            <div className="infoValue">
              {roomQuantity + '/' + guestQuantity}
            </div>
          </div>
        </div>
        <div className="infoRow">
          <div className="infoLabel">
            RATE
          </div>
          <div className="infoValue">
            {ratePlanId === ADVANCED? <span className="warning">Advanced Purchase (10% off)</span>: <span>Pay at Check-in</span>}
          </div>
        </div>
        <div className="breakDown">
          <div className="breakDownTitle">
            BREAKDOWN
          </div>
          <div className="breakDownRow">
            <div className="breakDownLabel">
              {(roomQuantity>1? roomQuantity + ' Rooms': roomQuantity + ' Room')
                + ' - '  + (nights>1? nights + ' Nights ': nights + ' Night ')
              }
              <br />
              {'\u00a0\u00a0\u00a0\u00a0' + (persistSelectedRooms.bed || 'Queen bed') + ' w/'
                + (persistSelectedRooms.hasWindows? ' Windows': ' skylight')
              }
            </div>
            <div className="breakDownValue">
              ${roomFee.toFixed(2)}
            </div>
          </div>
          {couponSuccess && (
            <div className="breakDownRow">
              <div className="breakDownLabel">
                <span className="warning">{`Coupon (${coupon.rateType==='FIXED_RATE'? '$'+ coupon.discount + ' off': coupon.discount + '% off'})`}</span>
              </div>
              <div className="breakDownValue">
                <span className="warning">-${couponDiscount.toFixed(2)}</span>
              </div>
            </div>
          )}
          {ratePlanId===ADVANCED && (
            <div className="breakDownRow">
              <div className="breakDownLabel">
                <span className="warning">Advanced Purchase (10% off)</span>
              </div>
              <div className="breakDownValue">
                <span className="warning">-${ratePlanDiscount.toFixed(2)}</span>
              </div>
            </div>
          )}
          <div className="breakDownRow">
            <div className="breakDownLabel">
              Municipal Accommodation Tax
              <PaymentToolTip
                title={
                  (
                    <div>
                      <div
                        style={{
                          width: '300px',
                          height: '90px',
                        }}
                      >
                        <span
                          style={{
                            color: colors.hoverBlue,
                            fontSize: '14px',
                            lineHeight: '18px',
                            fontWeight: 600,
                            fontStyle: 'italic',
                            fontFamily: 'Titillium Web',
                          }}
                        >
                          {'What is this?\u00A0\u00A0'}
                        </span>
                        <span
                          style={{
                            color: colors.black,
                            fontSize: '14px',
                            lineHeight: '18px',
                            fontFamily: 'Titillium Web',
                          }}
                        >
                          The Municipal Accommodation Tax is applied by the city of Mississauga on all hotel room rentals. The tax is 6% of the lodging cost, and is not applied to any other amenities. All hotels in Mississauga are obligated to charge this tax.
                        </span>
                      </div>
                    </div>
                  )
                }
              >
                <PriceQuestionIcon />
              </PaymentToolTip>  
            </div>
            <div className="breakDownValue">
              ${MAT.toFixed(2)}
            </div>
          </div>
          {addOnPricesArray}
          <div className="breakDownRow">
            <div className="breakDownLabel">
              HST
            </div>
            <div className="breakDownValue">
              ${HST.toFixed(2)}
            </div>
          </div>
        </div>
        <div className="totalRow">
          <div className="totalLabel">
            TOTAL
          </div>
          <div className="totalValue">
            ${total.toFixed(2)}
          </div>
        </div>
        <div className='termsOfServiceContainer'>
          <div className='termsOfService'>
            <Checkbox
              checked={termsOfServiceChecked}
              error={termsOfServiceError}
              borderColor={colors.labelGrey}
              onChange={() => {
                setTermsOfServiceChecked(!termsOfServiceChecked)
                localStorage.setItem('termsOfServiceChecked', (!termsOfServiceChecked).toString())
                setTermsOfServiceError(false)
              }}
              checkboxScale={1.5}
            />
            <div className='text'>
              <span className='plainText'
              onClick={() => {
                setTermsOfServiceChecked(!termsOfServiceChecked)
                localStorage.setItem('termsOfServiceChecked', (!termsOfServiceChecked).toString())
                setTermsOfServiceError(false)
              }}
              >I agree to receive phone calls, text messages, and emails from Rook Hotel; Additionally I agree to Rook Hotel’s </span>
              <span
                className='highLight'
                onClick={() => {
                  history.push('/termsAndConditions')
                }}
              >
                Terms of Service
              </span>
              <span className='required'> *</span>
            </div>


          </div>
          {termsOfServiceError? <div className='required'>Please accept the terms of service and try again.</div>: null}
        </div>

        
        <StyledButton
          className="checkOutButton"
          width="118px"
          height="48px"
          fontSize={18}
          disabled={loading}
          loading={loading}
          onClick={handleCheckOut}
        >
          {loading? '': 'Check out'}
        </StyledButton>
      </Card>
      <div className='couponContainer'>
        <FormInputField
          label="COUPON CODE"
          error={couponError}
          errorMessage={errorMessage}
          success={couponSuccess}
          successMessage={successMessage}
          borderColor={colors.signInBorderColor}
          onChange={(e) => {
            setCouponInput(e.target.value)
            if(couponError) {
              setCouponError(false)
            }
            if(couponSuccess) {
              setCouponSuccess(false)
            }
          }}
          onKeyDown={(e) => {
            if (e.key === 'Enter') {
              handleCouponApply()
            }
          }}
          value={couponInput}
          inputHeight="43px"
        />
        <StyledButton
          className="applyCouponButton"
          width="87px"
          height="43px"          
          onClick={handleCouponApply}
        >
          Apply
        </StyledButton>
      </div>
    </BookingSummaryContainer>
  )
}

export default BookingSummary