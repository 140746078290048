import styled from 'styled-components';
import { colors } from "constants/colors";
import { styleSizes } from "constants/styleSizes";

export const BannerWrapper = styled.div`
  @media (max-width: ${styleSizes.tabletBreakpoint}) {
    display: none;
  }
  display: flex;
  flex-direction: row;
  background-color: ${colors.black};
  padding: 16px 16px 16px 32px;
  justify-content: space-between;

  .text {
    display: flex;
    flex-direction: column;
    gap: 24px;
    justify-content: center;

    h3 {
      font-size: 32px;
      font-weight: 700;
      color: ${colors.white};
      padding: 0;
      margin: 0;
    }

    .textBook {
      font-size: 18px;
      font-weight: 400;
      color: ${colors.white};
    }
  }

  .image {
    display: flex;
    flex-direction: row;
    gap: 16px;

    img {
      width: 169px;
      height: 263px;
    }
  }
`;