import React, { FC, useEffect, useState } from 'react';
import { Dashboard } from './style';
import StyledButton from 'components/Button';
import { colors } from 'constants/colors';
import { Icon } from "components/Icon";
import make1 from 'assets/images/make-1.jpg';
import make2 from 'assets/images/make-2.jpg';
import { useHistory } from 'react-router';
// import { useLoadTrips } from 'customHooks/useLoadTrips';

import BookingBanner from 'components/BookingBanner';


import { useGetClosestBookingInfo } from 'customHooks/useGetClosestBookingInfo';

import moment from 'moment';

// import { Trips, Booking } from 'types/commonTypes'; 

import {
  CalendarV,
  HelpSupport
} from "assets/icons";


const DashboardContent: FC = () => {
  
  const history = useHistory()

  const [currentBookingCheckIn, setCurrentBookingCheckIn] = useState('');
  const [currentBookingCheckOut, setCurrentBookingCheckOut] = useState('');
  const [currentBookingGroupID, setCurrentBookingGroupID] = useState('');
  const [currentBookingStatus, setCurrentBookingStatus] = useState('');
  const [currentBookingStatusClass, setCurrentBookingStatusClass] = useState('');
  const [currentBookingButtonCaption, setCurrentBookingButtonCaption] = useState('Add Guest Info');
  const [currentBookingHeader, setCurrentBookingHeader] = useState('Upcoming Booking');
  const [currentBookingButtonURL, setCurrentBookingButtonURL] = useState('/');
  const [showBookingBanner, setShowBookingBanner] = useState(false);
  
  const [isCurrentBooking, setIsCurrentBooking] = useState(false);


  const debug = false;

  //const { trips, loading, refetch } = useLoadTrips()

  //const bookingInfo = useGetClosestBookingInfo();
  
  const { loading, bookingInfo:closestBookingInfo } = useGetClosestBookingInfo();


  useEffect(() => {

    if (closestBookingInfo) {
      if (debug) {
        console.log("Status:", closestBookingInfo.status);
        console.log("Check-In:", closestBookingInfo.checkIn);
        console.log("Check-Out:", closestBookingInfo.checkOut);
        console.log("GroupID:", closestBookingInfo.groupID);
        }
        
        setCurrentBookingCheckIn (closestBookingInfo.checkIn);
        setCurrentBookingCheckOut (closestBookingInfo.checkOut);
        setCurrentBookingGroupID (closestBookingInfo.groupID);
        setCurrentBookingStatus (closestBookingInfo.status);

      switch (closestBookingInfo.status) {
        case 'CONFIRMED':
          setCurrentBookingStatus("Confirmed");
          setCurrentBookingStatusClass("confirmed");
          setCurrentBookingButtonCaption("View Details");
          setCurrentBookingButtonURL('/account/bookings/' + closestBookingInfo.groupID);
          setCurrentBookingHeader ('Upcoming Booking');
          setShowBookingBanner(false);
          break;
        case 'INCOMPLETE':
          setCurrentBookingStatus("Incomplete *");
          setCurrentBookingStatusClass("incomplete");
          setCurrentBookingButtonCaption("Add Guest Info");
          setCurrentBookingButtonURL('/account/bookings/addGuestInfo/' + closestBookingInfo.groupID + '/1');
          setCurrentBookingHeader ('Upcoming Booking');
          setShowBookingBanner(false);
          break;
        case 'CHECKED_IN':
          setCurrentBookingStatus("Checked in");
          setCurrentBookingStatusClass("checkedIn");
          setCurrentBookingButtonCaption("View Details");
          setCurrentBookingButtonURL('/account/bookings/' + closestBookingInfo.groupID);
          setCurrentBookingHeader ('Upcoming Booking');
          setShowBookingBanner(false);
          break;
        case 'CHECKED_OUT':
          setCurrentBookingStatus("Checked out");
          setCurrentBookingStatusClass("checkedOut");
          setCurrentBookingButtonCaption("View Details");
          setCurrentBookingButtonURL('/account/bookings/' + closestBookingInfo.groupID);
          setCurrentBookingHeader ('Past Booking');
          setShowBookingBanner(true);
          break;
        default:
          setCurrentBookingStatus("");
      }

        setIsCurrentBooking (true);

    } else {
      if (debug) {
        console.log("No bookings.");
      }
      setShowBookingBanner(true);
      setIsCurrentBooking (false);
   }

  }, [closestBookingInfo]);


  // function formatDate(date: Date): string {
  //   return moment(date).format("ddd DD MMM, h:mm A");
  // }
  
  
  // Function below moved to useGetClosestBookingInfo hook

  // function getClosestBookingInfo(trips: Trips): { status: string; checkIn: string; checkOut: string; groupID: string } | null {
  //   let nearestBooking: Booking | null = null;
  //   let nearesCheckedOutBooking: Booking | null = null;
  //   let nearestBookingGroupStatus: string = '';
  //   let nearestCheckedOutBookingGroupStatus: string = '';
  //   let nearestBookingGroupID: string = '';
  //   let nearestCheckedOutBookingGroupID: string = '';

  //   // Get the current date (today's date)
  //   const today = new Date();

  //   // Iterate through all groups in trips
  //   for (const group of Object.values(trips)) {


  //     // Initial status:
  //     let currentGroupBookingStatus = "CONFIRMED";

  //     let checkedOutStatusCounter = 0;
  //     let expiterdStatusCounter = 0;
  //     let cancelledStatusCounter = 0;
  //     let incompliteBookings = 0;

  //     group.bookings.forEach(currntBooking => {

  //       switch (currntBooking.status) {
  //         case "CHECKED_OUT":
  //           checkedOutStatusCounter++;
  //           break;
  //         case "CHECKED_IN":
  //           // If one CHECKED_IN -> group CHECKED_IN
  //           currentGroupBookingStatus = "CHECKED_IN";
  //           break;
  //         case "EXPIRED":
  //           expiterdStatusCounter++;
  //           break;
  //         case "CANCELLED":
  //           cancelledStatusCounter++;
  //           break;
  //         case "PENDING":

  //           if (!(currntBooking.fullName && currntBooking.phoneNumber)) {
  //             incompliteBookings++;
  //           }

  //           break;
  //       }

  //       // If everyone CHECKED_OUT -> group CHECKED_OUT
  //       if (checkedOutStatusCounter === group.bookings.length) {
  //         currentGroupBookingStatus = "CHECKED_OUT";
  //       }

  //       // If everyone EXPIRED -> group EXPIRED
  //       if (expiterdStatusCounter === group.bookings.length) {
  //         currentGroupBookingStatus = "EXPIRED";
  //       }

  //       // If everyone CANCELLED -> group CANCELLED
  //       if (cancelledStatusCounter === group.bookings.length) {
  //         currentGroupBookingStatus = "CANCELLED";
  //       }

  //       // If only CANCELLED and EXPIRED in group -> group EXPIRED

  //       // Vadim: If one booking in grop is CANCELLED and one is EXPIRED what group status will be?
  //       // Shamim: I think we should use EXPIRED for this one since we will still charge the guest.
  //       if (
  //         (cancelledStatusCounter + expiterdStatusCounter === group.bookings.length) &&
  //         cancelledStatusCounter > 0 &&
  //         expiterdStatusCounter > 0
  //       ) {
  //         currentGroupBookingStatus = "EXPIRED";
  //       }


  //     });

  //     // If thre is INCOMPLETE 1 or more bookings -> group INCOMPLETE
  //     if (currentGroupBookingStatus === 'CONFIRMED' && incompliteBookings > 0) {
  //       currentGroupBookingStatus = 'INCOMPLETE';
  //     }


  //     // Determinating group status END

  //     for (const booking of group.bookings) {

  //       const checkOutDate = new Date(booking.checkOutDate);


  //       if (debug) {
  //         console.log ('checkOutDate = ', checkOutDate);
  //         console.log ('today = ', today);
  //       }

  //       // if (checkOutDate < today) {
  //       //   console.log ("Skip the booking...");
  //       //   continue; // Skip the booking if checkOut date is in the past
  //       // }


  //       if (
  //         (currentGroupBookingStatus === "CONFIRMED" || currentGroupBookingStatus === "INCOMPLETE" || currentGroupBookingStatus === "CHECKED_IN") &&
  //         (!nearestBooking || new Date(booking.checkInDate) < new Date(nearestBooking.checkInDate)) &&
  //         (checkOutDate > today) // Skip the booking if checkOut date is in the past
  //       ) {
  //         nearestBooking = booking; // Assign booking to nearestBooking
  //         nearestBookingGroupStatus = currentGroupBookingStatus; // Assign booking to nearestBooking
  //         nearestBookingGroupID = group.groupId;
  //       }

  //       if (
  //         (currentGroupBookingStatus === "CHECKED_OUT") &&
  //         (!nearesCheckedOutBooking || new Date(booking.checkInDate) < new Date(nearesCheckedOutBooking.checkInDate))
  //       ) {
  //         nearesCheckedOutBooking = booking; // Assign booking to nearesCheckedOutBooking
  //         nearestCheckedOutBookingGroupStatus = currentGroupBookingStatus; // Assign booking to nearesCheckedOutBooking
  //         nearestCheckedOutBookingGroupID = group.groupId;
  //       }

  //     }
  //   }

  //   if (debug) {
  //     console.log ("nearestBooking: ", nearestBooking);
  //     console.log ("nearesCheckedOutBooking: ", nearesCheckedOutBooking);
  //   }

  //   // If no suitable booking, but there is CheckedOutBooking, show CheckedOutBooking
  //   if (!nearestBooking && nearesCheckedOutBooking) {
  //     nearestBooking = nearesCheckedOutBooking;
  //     nearestBookingGroupStatus = nearestCheckedOutBookingGroupStatus;
  //     nearestBookingGroupID = nearestCheckedOutBookingGroupID;

  //   }
    
  //   // If no suitable booking is found, return null
  //   if (!nearestBooking) {
  //     return null;
  //   }

  //   // Safely access properties of the nearestBooking
  //   return {
  //     status: nearestBookingGroupStatus,
  //     checkIn: nearestBooking.checkInDate
  //       ? new Intl.DateTimeFormat('en-GB', {
  //         weekday: 'short', // 'Fri'
  //         day: '2-digit', // '18'
  //         month: 'short', // 'Sep'
  //         hour: '2-digit', // '11'
  //         minute: '2-digit', // '00'
  //         hour12: true // 'AM'
  //       }).format(new Date(nearestBooking.checkInDate))
  //       : "Unknown Date",
  //     checkOut: nearestBooking.checkOutDate
  //       ? new Intl.DateTimeFormat('en-GB', {
  //         weekday: 'short', // 'Fri'
  //         day: '2-digit', // '18'
  //         month: 'short', // 'Sep'
  //         hour: '2-digit', // '11'
  //         minute: '2-digit', // '00'
  //         hour12: true // 'AM'
  //       }).format(new Date(nearestBooking.checkOutDate))
  //       : "Unknown Date",
  //     groupID: nearestBookingGroupID
  //   };
  // }
  
  if (loading) {
    return (
            <Dashboard></Dashboard>
    )
  }



  return (
    <Dashboard>
          {showBookingBanner ? (
                <BookingBanner
                buttonText="Book Now"
                buttonUrl="/"
                mainText="Make a quick getaway."
                secondaryText="Book a room and make your escape."
                image1={make2}
                image2={make1}
                buttonWidth = "118px"
              />
          //   <div className="dashboardBanner">
          //   <div className="text">
          //     <h3>Make a quick getaway.</h3>
          //     <div className="textBook">Book a room and make your escape.</div>
          //     <StyledButton
          //         width="118px"
          //         height="48px"
          //         disabledBackgroundColor={colors.signInBorderColor}
          //         disabledColor={colors.white}
          //         onClick={() => history.push('/')}
          //       >
          //         Book Now
          //       </StyledButton> 
          //   </div>
          //   <div className="image">
          //    <img src={make2} alt="Make a quick getaway" width="169" height="263" />
          //    <img src={make1} alt="Make a quick getaway" width="169" height="263" />
          //   </div>
      
          // </div>
    ) : null}

    {isCurrentBooking ? (
      <div className="accountBody accountBodyBookingDetails">
      <div className="cardContent">
        <div className="header">
          <Icon className="bookingIcon">
                <CalendarV />
          </Icon>
          <h3>{currentBookingHeader}</h3>
        </div>
        
        <div className="bookingDetails">
          <div className="detail">
            <div className="title">STATUS:</div>
            <div className={`value ${currentBookingStatusClass}`}>{currentBookingStatus}</div>
          </div>
          <div className="checkinout">
          <div className="detail">
            <div className="title">CHECK-IN:</div>
            <div className="value">{currentBookingCheckIn}</div>
          </div>
          <div className="detail">
            <div className="title">CHECK-OUT:</div>
            <div className="value">{currentBookingCheckOut}</div>
          </div>
         </div>
        </div>

          <StyledButton
            width="150px"
            height="48px"
            //backgroundColor = {colors.signInBorderColor}
            //fontColor = {colors.white}
            disabledBackgroundColor={colors.signInBorderColor}
            disabledColor={colors.white}
            onClick={() => history.push(currentBookingButtonURL)}
            // disabled={oldPassword.length < 8 || newPassword.length < 8}
          >
            {currentBookingButtonCaption}
          </StyledButton>
          {currentBookingStatusClass === "incomplete" ? (
          <div className="note">
          <span>*</span> Adding guest details will make check-in faster when you arrive.
          </div>
          ) : null }

      </div>
    </div>
    ) : null }

     
      <div className="accountBody accountBodyMoreHelp">

        <div className="cardContent moreHelp">
          <div className="helpCont">
            <div className="text">
            Our mission is your happiness! Let us know how we can help. <br />
            Our team is here 24/7 to assist you.
            </div>
            <div className="detail">
              <div className="title">PHONE NUMBER:</div>
              <a className="value" href="tel:9059494000">(905) 949-4000</a>
            </div>
            <div className="detail">
              <div className="title">Email:</div>
              <a className="value" href="mailto:help@rookhotel.com">help@rookhotel.com</a>
            </div>
          </div>

          <div  className="helpMore">
          <Icon width={61} height={56}>
                <HelpSupport />
            </Icon>
            <div className="helpMoreText">Need More Support?</div>
            <StyledButton
              width="120px"
              height="48px"

              disabledBackgroundColor={colors.signInBorderColor}
              disabledColor={colors.white}
              onClick={() => history.push('/help')}
            >
              More Help
            </StyledButton> 
          </div>

        </div>
      </div>
    </Dashboard>
  );
};

export default DashboardContent;
