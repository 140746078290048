import React from 'react';
import { useHistory } from 'react-router-dom';
import { BannerWrapper } from './styles';
import StyledButton from 'components/Button';
import { colors } from "constants/colors";

interface BookingBannerProps {
  buttonText: string;
  buttonUrl: string;
  mainText: string;
  secondaryText: string;
  image1: string;
  image2: string;
  buttonWidth: string;
}

const BookingBanner: React.FC<BookingBannerProps> = ({
  buttonText,
  buttonUrl,
  mainText,
  secondaryText,
  image1,
  image2,
  buttonWidth
}) => {
  const history = useHistory();

  const handleButtonClick = () => {
    history.push(buttonUrl);
  };

  return (
    <BannerWrapper className="banner">
      <div className="text">
        <h3>{mainText}</h3>
        <div className="textBook">{secondaryText}</div>
        <StyledButton
          width={buttonWidth}
          height="48px"
          disabledBackgroundColor={colors.signInBorderColor}
          disabledColor={colors.white}
          onClick={handleButtonClick}
        >
          {buttonText}
        </StyledButton>
      </div>
      <div className="image">
        <img src={image1} alt={mainText} width="169" height="263" />
        <img src={image2} alt={mainText} width="169" height="263" />
      </div>
    </BannerWrapper>
  );
};

export default BookingBanner;