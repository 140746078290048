import styled from 'styled-components';
import { BasicSectionContainer } from '../../../style';
import { colors } from "constants/colors";
import { styleSizes } from "constants/styleSizes";

export const Dashboard = styled(BasicSectionContainer)`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  align-items: stretch;
  gap: 32px;
  .accountBodyBookingDetails {
    border: 1px solid ${colors.hoverBlue};
    @media (max-width: ${styleSizes.mobileBreakpoint}) {
            padding: 16px;
    }
  }
  .accountBodyMoreHelp {
    border: 1px solid ${colors.optionBackground};
    @media (max-width: ${styleSizes.mobileBreakpoint}) {
            padding: 16px;
    }
  }
  /* .dashboardBanner {
    @media (max-width: ${styleSizes.tabletBreakpoint}) {
            display: none;
    }
    display: flex;
    flex-direction: row;
    background-color: ${colors.black};
    padding: 16px 16px 16px 32px;
    justify-content: space-between;
    .text {
      display: flex;
      flex-direction: column;
      gap:24px;
      justify-content: center;
      h2 {
        font-size: 32px;
        font-weight: 700;
        color: ${colors.white};
        padding: 0;
        margin: 0;
      }
      .textBook {
        font-size: 18px;
        font-weight: 400;
        color: ${colors.white};
      }
    }
    .image {
        display: flex;
        flex-direction: row;
        gap: 16px;
        img {
          width: 169px;
          height: 263px;
        }
    }
  } */
  .bookingIcon {
    width: 35px;
    height: 32px;
    @media (max-width: ${styleSizes.tabletBreakpoint}) {
      width: 28px;
      height: 26px;
      }
  }
  .header {
    display: flex;
    flex-direction: row;
    gap: 16px;
    margin-bottom: 24px;
    align-items: center;
    @media (max-width: ${styleSizes.tabletBreakpoint}) {
      gap: 8px;
      }

    h3 {
      font-size: 25px;
      font-weight: 600;
      padding: 0;
      margin: 0;
      @media (max-width: ${styleSizes.tabletBreakpoint}) {
          font-size: 18px;
      }
    }

  }
  .bookingDetails {
      display: flex;
      flex-direction: row;
      margin-bottom: 16px;
      align-items: center;
      flex-wrap: wrap;
      .detail {
        margin-bottom: 16px;
        margin-right: 32px;
        @media (max-width: ${styleSizes.mobileBreakpoint}) {
          margin-right: 0;
      }
      }
    }
    .checkinout {
      display: flex;
      flex-direction: row;
      align-items: center;
      flex-wrap: wrap;
      }
    .note {
      margin-top: 24px;
      font-size: 16px;
      font-weight: 400;
      span {
        color: ${colors.red}
      }
    }
    .moreHelp {
      display: flex;
      flex-direction: row;
      gap: 20px;
      @media (max-width: ${styleSizes.mobileBreakpoint}) {
        flex-direction: column;
    }
      .helpCont {
        flex: 4;
        display: flex;
        flex-direction: column;
        gap: 24px;
        .text {
          font-size: 18px;
          font-weight: 400;
          line-height: 30px;
        }
      }
      .helpMore {
        flex: 2;
        display: flex;
        flex-direction: column;
        align-items: center;
        gap: 8px;
        justify-content: flex-start;
        font-size: 18px;
        font-weight: 600;
        .helpMoreText {
          text-align: center;
          white-space: nowrap;
        }
      }
    }
    .detail {
        display: flex;
        flex-direction: row;
        align-items: center;
        gap: 8px;
        .title {
          font-size: 18px;
          font-weight: 600;
        }
        .value {
          display: block;
          font-size: 16px;
          font-weight: 400;
       }
      }
`;