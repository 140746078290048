import styled from 'styled-components';
import { colors } from 'constants/colors';
import { BasicSectionContainer } from '../../../../../style';

export const AddGuestInfoContainer = styled(BasicSectionContainer)`


    .accountBody {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    align-items: stretch;
    gap: 32px;

    .room {
      font-size: 24px;
      font-weight: 600;
      margin: 0;
      padding: 0 0 32px 0;
    }

    .roomOptions {
      display: flex;
      flex-direction: row;
      gap: 64px;
      margin-bottom: 32px;
      .roomType, .roomFeatures {
        display: flex;
        flex-direction: column;
        gap: 10px;
      }
      .optionTitle {
          font-size: 14px;
          font-weight: 400;
      }
      .optionContent {
          font-size: 16px;
          font-weight: 600;
      }
    }
    .roomForm {
      .formBody {
        display: flex;
        flex-direction: row;
        gap: 23px;

        .phoneCel {
            flex: 244;
        }
        .nameCel {
          flex: 340;
        }
        .numberOfKeysCel {
          flex: 160;
          display: flex;
          flex-direction: row;
          gap: 8px;
          margin-top: 25px;
          margin-bottom: 21px;
        }

      }
      .formControls {
        margin-top: 11px;
          display: flex;
          flex-direction: row;
          justify-content: flex-end;
      }
    }

    .warning {
    width: 100%;
    height: 20px;
    line-height: 20px;
    position: absolute;
    color: ${colors.red};
    font-size: 16px;
    font-weight: 600;
    white-space: normal;
    overflow: visible;
    text-overflow: unset;
    text-align: left;
    margin-top: 5px;
}

    }

`;
