import React, { FC } from 'react';
import { Switch, Route, Redirect } from 'react-router-dom'
import OverAll from './components/OverAll';
import BookingDetails from './components/BookingDetails';
import AddGuestInfo from './components/AddGuestInfo';
import ModifyDates from './components/ModifyDates';
import AddOns from './components/AddOns';
import CancelGroupBooking from './components/CancelGroupBooking';

const AccountContent: FC = () => {

  return (
    <Switch>
      <Route path={'/account/bookings/addGuestInfo/:groupId/:roomId'} render={() => <AddGuestInfo />} />
      <Route path={'/account/bookings/editGuestInfo/:groupId/:roomId'} render={() => <AddGuestInfo />} />
      <Route path={'/account/bookings/modifyDates/:groupId/:confirmationId'} render={() => <ModifyDates />} />
      <Route path={'/account/bookings/addOns/:groupId/:confirmationId'} render={() => <AddOns />} />
      <Route path={'/account/bookings/CancelGroupBooking/:groupId'} render={() => <CancelGroupBooking />} />
      <Route path={'/account/bookings/:groupId'} render={() => <BookingDetails />} />
      <Route path={'/account/bookings/'} render={() => <OverAll />} />
      <Redirect to={'/account/bookings'} />
    </Switch>
  );
};

export default AccountContent;