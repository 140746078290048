import React, { FC, useEffect } from 'react';
import { useHistory, useParams, useLocation } from 'react-router-dom';
import { AccountContentContainer } from './style';
import Card from 'components/Card';
import { useLoadTrips } from 'customHooks/useLoadTrips';
import { useLoadBookingsByGroupID } from 'customHooks/useLoadBookingsByGroupID';
import { ADVANCED, ADVANCED_RATE } from 'constants/ratePlanId';
import { HST_RATE, MAT_RATE } from 'constants/constant';
import moment from 'moment';
import {  DownloadRecept, EditNewIcon, Bin } from 'assets/icons';
import { Icon } from 'components/Icon';
import { Modify } from 'ol/interaction';
import ModifyDatesContent from '../ModifyDates';
import { useRecoilState } from 'recoil';
import { cancelBookingModalOpenState, cancelReasonModalOpenState, cancelGroupId, cancelBookingId, canceledGroupIdState, cancelBookingFee, canceledBookingIdState } from 'states/ModalStates/atoms';
import {globalMessageState, globalErrorState} from 'states/CommonStates/atoms';

import CancelBookingModalBody from '../CancelBookingModalBody';
import CancelReasonModalBody from '../CancelReasonModalBody';
import Modal from 'components/Modal';
import {bookingsStatuses} from 'constants/constant';

import AlertMessage from 'components/AlertMessage';
import BookingBannerCompleteYourBooking from 'components/BookingBannerCompleteYourBooking';



const debug = true;

const BookingDetailsContent: FC = () => {

  const [openCancelBookingModal, setOpenCancelBookingModal] = useRecoilState(cancelBookingModalOpenState)
  const [openCancelReasonModal, setOpenCancelReasonModal] = useRecoilState(cancelReasonModalOpenState)
  const [cancelModalGroupId, setCancelModalGroupId] = useRecoilState(cancelGroupId)
  const [cancelModalBookingId, setCancelModalBookingId] = useRecoilState(cancelBookingId)

  const [messageState, setMessageState] = useRecoilState(globalMessageState);
  const [errorState, setErrorState] = useRecoilState(globalErrorState);
  const closeMessage = () => setMessageState({ isVisible: false, text: '' });
  const closeError = () => setErrorState({ isVisible: false, text: '' });


  const history = useHistory()
  const { trips, loading, refetch } = useLoadTrips()
  const { groupId } = useParams<{groupId: string}>()
  const trip = trips[groupId]

  const [canceledGroupId, setCanceledGroupId] = useRecoilState(canceledGroupIdState);




  useEffect(() => {
    if (canceledGroupId) {
      history.push(`/account/bookings`)
    }
  }, [canceledGroupId]);




  if (debug) {
    console.log ("trip =", trip);
  }

  // Old code with trips
  if (loading || !trip) {
    return (
      <AccountContentContainer>
      <div className="accountBody">
        {/* <div className="loading">Loading...</div> */}
      </div>
      </AccountContentContainer>
    );
  }





  /* Consts for output: */

   // Old code with trips
  const trip_addons = trip.addOns?.map((groupSubscribedAddOn) =>
    groupSubscribedAddOn.bookingAddOn.label
  )
    .join(", ");


// Booking addins

    // const booking_addons = bookingsLoaded.addons
    // ? bookingsLoaded.addons
    //   .map(addon => addon.bookingAddOn?.title || '')
    //   .filter(title => title) // 
    //   .join(', ')
    // : '';

  // Old code with trips
    const is_group_booking = trip.bookings.length > 1 ? true : false;
  
  
    // New code with bookings
  //const is_group_booking = bookingsLoaded.length > 1 ? true : false;
  

  const trip_number_of_rooms = trip.numberOfRooms;

//  const trip_number_of_guests = trip.numberOfGuests;


// Vadim: is there may be any other option for rateChangeType than decrease_by_percent?
// Emmy: decrease_by_percent , increase_by_percent, decrease_by_amount , increase_by_amount


  const trip_rateplan = trip.ratePlan?.ratePlan?.title;

    //const trip_number_of_nights = trip.bookings[0]?.roomBookings[0]?.numberOfNights;

    const trip_total_number_of_nights = trip.bookings.reduce((total, booking) => {
      const number_of_nights_in_booking = booking.roomBookings.reduce((sum, room_booking) => {
        return sum + (room_booking.numberOfNights ?? 0); // undefined check
      }, 0);
    
      return total + number_of_nights_in_booking;
    }, 0);

    const trip_bills_total = trip.bills?.reduce((sum, bill) => {
      return sum + (bill.total ?? 0); // undefined check
    }, 0);

    const trip_hst = trip.bills?.reduce((sum, bill) => {
      return sum + (bill.HST ?? 0); // undefined check
    }, 0);

    const trip_mat = trip.bills?.reduce((sum, bill) => {
      return sum + (bill.MAT ?? 0); // undefined check
    }, 0);

    const bookings = trip.bookings;

    const ModifyRoomDates = (confirmation_id: string) => {
      if (debug) {
        console.log('confirmation_id = ' + confirmation_id);
      }
      history.push(`/account/bookings/modifyDates/${groupId}/${confirmation_id}`);
    };

    const ModifyRoomAddons = (confirmation_id: string) => {
      if (debug) {
        console.log('confirmation_id = ' + confirmation_id);
      }
      history.push(`/account/bookings/addOns/${groupId}/${confirmation_id}`);
    };

    const CancelBooking = () => {
      if (is_group_booking) {
        history.push(`/account/bookings/CancelGroupBooking/${groupId}`);
      }
      else {
        setCancelModalGroupId(groupId)
        setCancelModalBookingId(trips[groupId].bookings[0]?.bookingId)
        setOpenCancelBookingModal(true)
      }
    };


    const guestsArray = Object.keys(bookings).map((index) => {
      
      const checkInDate = moment (bookings[index].checkInDate).format("ddd DD MMM h:mm A");
      const checkOutDate = moment (bookings[index].checkOutDate).format("ddd DD MMM h:mm A");

     
      if (bookings[index].status == "CANCELLED" || bookings[index].isDeleted) {
        return null;
      }

      return (
        <div className="guestsTableContent" key={index}>
          <div className="col name">{bookings[index].fullName}</div>
          <div className="col">{bookings[index].phoneNumber}</div>
          <div className="col">{checkInDate}</div>
          <div className="col">{checkOutDate}</div>
          <div className="col">
            <span className="edit" 
            onClick={()=> {
              history.push(`/account/bookings/editGuestInfo/${groupId}/${parseInt(index, 10) + 1}`);
              window.scrollTo(0, 0);
            }}
            >Edit</span>
          </div>
        </div>
      );
    })

    const totalRooms =  Object.keys(bookings).length;

    let showRoomNumber = false;
    let cancelBookingText = 'Cancel Booking';

    if (totalRooms > 1) {
        showRoomNumber = true;
        cancelBookingText = 'Cancel Entire Booking';

    }

    if (debug) {
      console.log ('totalRooms = ' + totalRooms);
    }

    const roomsArray = Object.keys(bookings).map((index) => {
      
      const checkInDate = moment (bookings[index].checkInDate).format("ddd DD MMM h:mm A");
      const checkOutDate = moment (bookings[index].checkOutDate).format("ddd DD MMM h:mm A");

      // for group booking


      // Booking addins

    // const booking_addons = bookingsLoaded.addons
    // ? bookingsLoaded.addons
    //   .map(addon => addon.bookingAddOn?.title || '')
    //   .filter(title => title) // 
    //   .join(', ')
    // : '';


      let rooomNumber = +index + 1;

      const trip_checkin = moment(
        bookings?.reduce((min, current) => {
          return current.checkInDate < min ? current.checkInDate : min;
        }, bookings[index]?.checkInDate)
      ).format("ddd, DD MMM"); // old format was format("ddd DD MMM, h:mm A")
    
      const trip_checkout = moment(
        bookings?.reduce((max, current) => {
            return current.checkOutDate > max
              ? current.checkOutDate
              : max;
          }, bookings[index]?.checkOutDate)
        ).format("ddd, DD MMM"); // old format was format("ddd DD MMM, h:mm A")
    

      const trip_confirmation_code = bookings[index]?.reservationCode;
      
      //const trip_number_of_guests = bookingsLoaded[index].tenantGroup.tenant.user;

      const trip_number_of_guests = 1;

      //console.log(trip_number_of_guests);

      let booking_status = bookings[index]?.status;
      let allow_booking_modification = false;

      let booking_rateplan = trip.ratePlan?.ratePlan?.title;

      let booking_room_type = bookings[index]?.roomBookings[0].room.roomType.name + " - " + bookings[index]?.roomBookings[0].room.roomType.bed + " " + (bookings[index]?.roomBookings[0].room.roomType.hasWindows ? "w/ window" : "w/ skylight");

      if (debug) {
        console.log ("rate plan:", booking_rateplan);
      }
      
      let booking_rateplan_off = '';

      const rateChangeValue = trip.ratePlan?.ratePlan?.rateChangeValue ?? 0;
      
      if (rateChangeValue > 0) {
         booking_rateplan_off = `(${rateChangeValue}% off)`;
      }

      if (booking_status === 'PENDING') {

        allow_booking_modification = true;

        if (!(bookings[index]?.fullName && bookings[index]?.phoneNumber)) {
          booking_status = 'INCOMPLETE';
        }
        else {
          booking_status = 'CONFIRMED';
        }

      }

      let allow_remove = false;
      
      if ((booking_status !== "CHECKED_OUT") && (booking_status !== "CANCELLED") && (booking_status !== "EXPIRED")) {
        allow_remove = true;
      }

      return (
        <div className="room" key={rooomNumber}>
            
          {showRoomNumber && <div className="room">Room {rooomNumber}</div>}

        <div className="detailsHeader">
            STATUS:{" "}
            <span className={bookingsStatuses[booking_status]?.className || 'checkedIn'}>
              {bookingsStatuses[booking_status]?.text?.toUpperCase()}
            </span>
            {allow_remove && (
              <div className="accountLink accountLinkRed">
                <Icon width={11} height={13}>
                  <Bin />
                </Icon>
                <div
                  className="link"
                  onClick={() => ModifyRoomDates(trip_confirmation_code)}
                >
                  Remove Room
                </div>
              </div>
            )}
          </div>
          <div className="detailsLarge">
          ROOM TYPE{" "}
            <span>
              {booking_room_type}
            </span>

            {allow_booking_modification && (
              <div className="accountLink">
                <Icon width={14} height={14}>
                  <EditNewIcon />
                </Icon>
                <div
                  className="link"
                  onClick={() => ModifyRoomDates(trip_confirmation_code)}
                >
                  Modify Room
                </div>
              </div>
            )}

          </div>
          <div className="detailsLarge">
            CHECK-IN/CHECK-OUT{" "}
            <span>
              {trip_checkin} - {trip_checkout}
            </span>

            {allow_booking_modification && (
              <div className="accountLink">
                <Icon width={14} height={14}>
                  <EditNewIcon />
                </Icon>
                <div
                  className="link"
                  onClick={() => ModifyRoomDates(trip_confirmation_code)}
                >
                  Modify Dates
                </div>
              </div>
            )}

          </div>
          <div className="detailsLarge">
            CONFIRMATION <span>{trip_confirmation_code}</span>
          </div>
          <div className="detailsLarge">
            EXTRA <span>{/*booking_addons*/} </span>
            {allow_booking_modification && (
              <div className="accountLink">
                <Icon width={14} height={14}>
                  <EditNewIcon />
                </Icon>
                <div className="link"
                  onClick={() => ModifyRoomAddons(trip_confirmation_code)}
                >Modify Addons</div>
              </div>
            )}
          </div>
          <div className="detailsLarge">
            RATE{" "}
            <span>
              {booking_rateplan} {booking_rateplan_off}
            </span>
          </div>
        </div>
      );
    })


    if (debug) {
      console.log(trip);
      //console.log (trips);

      console.log (is_group_booking);
    }
  

  return (
    <AccountContentContainer>
                  <AlertMessage
                showMessage={messageState.isVisible}
                //messageType = 'error'
                onClose={() => closeMessage()} // Changing state on close
            >
                {messageState.text}
            </AlertMessage>
            
            <AlertMessage
                showMessage={errorState.isVisible}
                messageType = 'error'
                onClose={() => closeError()} // Changing state on close
            >
                {errorState.text}
            </AlertMessage>

            <BookingBannerCompleteYourBooking 
            />
      <div className="accountBody">
        <div className="cardContent">
          <div className="cancelBooking"
            onClick={() => CancelBooking()}
          >{cancelBookingText}</div>
          {roomsArray}

          <div className="detailsLarge breakDown">BREAKDOWN</div>
          <div className="breakDownLine">
            <div className="breakDownTitle">
              {trip_number_of_rooms} Room - {trip_total_number_of_nights} Nights
            </div>
            <div className="breakDownDots"></div>
            <div className="breakDownNumber"></div>
          </div>
          <div className="breakDownLine">
            <div className="breakDownTitle breakDownTitleWithPadding">
              Queen bed w/ skylight
            </div>
            <div className="breakDownDots">
              <div className="dashedLine"></div>
            </div>
            <div className="breakDownNumber">$???.??</div>
          </div>
          <div className="breakDownLine breakDownLineRed">
            <div className="breakDownTitle">Advanced Purchase (10% off)</div>
            <div className="breakDownDots">
              <div className="dashedLineRed"></div>
            </div>
            <div className="breakDownNumber">-${+(trip_bills_total / 10).toFixed(2)}</div>
          </div>
          <div className="breakDownLine breakDownLineRed">
            <div className="breakDownTitle">Coupon Code existentialdread</div>
            <div className="breakDownDots">
              <div className="dashedLineRed"></div>
            </div>
            <div className="breakDownNumber">-$???.??</div>
          </div>
          <div className="breakDownLine">
            <div className="breakDownTitle">Municipal Accommodation Tax</div>
            <div className="breakDownDots">
              <div className="dashedLine"></div>
            </div>
            <div className="breakDownNumber">${trip_mat}</div>
          </div>
          <div className="breakDownLine">
            <div className="breakDownTitle">Parking</div>
            <div className="breakDownDots">
              <div className="dashedLine"></div>
            </div>
            <div className="breakDownNumber">$???.??</div>
          </div>
          <div className="breakDownLine">
            <div className="breakDownTitle">Breakfast</div>
            <div className="breakDownDots">
              <div className="dashedLine"></div>
            </div>
            <div className="breakDownNumber">$???.??</div>
          </div>
          <div className="breakDownLine">
            <div className="breakDownTitle">Late Checkout</div>
            <div className="breakDownDots">
              <div className="dashedLine"></div>
            </div>
            <div className="breakDownNumber">$???.??</div>
          </div>
          <div className="breakDownLine">
            <div className="breakDownTitle">HST</div>
            <div className="breakDownDots">
              <div className="dashedLine"></div>
            </div>
            <div className="breakDownNumber">${trip_hst}</div>
          </div>
          <div className="breakDownTotal">
            <div className="breakDownTitle">TOTAL</div>
            <div className="breakDownDots"></div>
            <div className="breakDownNumber">${trip_bills_total}</div>
          </div>
          <div className="downloadReceipt">
            <div className="accountLink">
              <Icon width={17} height={18}>
                <DownloadRecept />
              </Icon>
              <div className="link">Download Receipt - ???</div>
            </div>
          </div>
        </div>
        {is_group_booking ? (
        <div className="cardContent">
          <a id="guestinfo"></a>
          <h2 className="guestsHeader">Guest Information</h2>
          <div className="guestsTableHeader">
            <div className="col">GUEST NAME</div>
            <div className="col">PHONE NUMBER</div>
            <div className="col">CHECK-IN</div>
            <div className="col">CHECK-OUT</div>
            <div className="col">ACTION</div>
          </div>
            {guestsArray}
        </div>
        ) : null}

      </div>
        <Modal open={openCancelBookingModal} >
          <CancelBookingModalBody />
        </Modal>
        <Modal open={openCancelReasonModal} >
          <CancelReasonModalBody />
        </Modal>
      {/* <Card padding="32px">
        <div className="cardTitle">
          <span>Status: </span>
          <span className="highLight">{trip?.status}</span>
        </div>
        <div className="cardContent">
          <div className="breakDownRow">
            <div className="breakDownLabel">CHECK-IN/CHECK-OUT</div>
            <div className="breakDownValue">
              {moment(
                trips[groupId].bookings?.reduce((min, current) => {
                  return current.checkInDate < min ? current.checkInDate : min;
                }, trips[groupId].bookings[0]?.checkInDate)
              ).format("ddd DD MMM, h:mm A") +
                "-" +
                moment(
                  trips[groupId].bookings?.reduce((max, current) => {
                    return current.checkOutDate > max
                      ? current.checkOutDate
                      : max;
                  }, trips[groupId].bookings[0]?.checkOutDate)
                ).format("ddd DD MMM, h:mm A")}
            </div>
          </div>
          <div className="breakDownRow">
            <div className="breakDownLabel">CONFIRMATION</div>
            <div className="breakDownValue">
              ${trip.bookings[0]?.reservationCode}
            </div>
          </div>
          <div className="breakDownRow">
            <div className="breakDownLabel">ROOMS / GUESTS</div>
            <div className="breakDownValue">
              {trip.numberOfRooms + "/" + trip.numberOfGuests}
            </div>
          </div>
          <div className="breakDownRow">
            <div className="breakDownLabel">EXTRA</div>
            <div className="breakDownValue">
              {trip.addOns
                ?.map(
                  (groupSubscribedAddOn) =>
                    groupSubscribedAddOn.bookingAddOn.label
                )
                .join(", ")}
            </div>
          </div>
          <div className="breakDownRow">
            <div className="breakDownLabel">RATE</div>
            <div className="breakDownValue">
              ${trip.ratePlan?.ratePlan?.title}
            </div>
          </div>
          <div className="breakDown">
            <div className="breakDownTitle">BREAKDOWN</div> */}

      {/* <div className="breakDownRow">
              <div className="breakDownLabel">
                {((trip.numberOfRooms ?? 0) > 1 ? (trip.numberOfRooms ?? 0) + ' Rooms' : (trip.numberOfRooms ?? 0) + ' Room')
                  + ' - '  + (nights>1? nights + ' Nights ': nights + ' Night ')
                }
                <br />
                {'\u00a0\u00a0\u00a0\u00a0' + (.bed || 'Queen bed') + ' w/'
                  + (persistSelectedRooms.hasWindows? ' Windows': ' skylight')
                }
              </div>
              <div className="breakDownValue">
                ${roomFee.toFixed(2)}
              </div>
            </div> */}
      {/* {couponSuccess && (
              <div className="breakDownRow">
                <div className="breakDownLabel">
                  <span className="warning">{`Coupon (${trip.coupon.rateType==='FIXED_RATE'? '$'+ trip.coupon.discount + ' off': trip.coupon.discount + '% off'})`}</span>
                </div>
                <div className="breakDownValue">
                  <span className="warning">-${couponDiscount.toFixed(2)}</span>
                </div>
              </div>
            )}
            {ratePlanId===ADVANCED && (
              <div className="breakDownRow">
                <div className="breakDownLabel">
                  <span className="warning">Advanced Purchase (10% off)</span>
                </div>
                <div className="breakDownValue">
                  <span className="warning">-${ratePlanDiscount.toFixed(2)}</span>
                </div>
              </div>
            )}
            <div className="breakDownRow">
              <div className="breakDownLabel">
                Municipal Accommodation Tax
              </div>
              <div className="breakDownValue">
                ${MAT.toFixed(2)}
              </div>
            </div> */}
      {/* </div>
          i
        </div>
      </Card> */}
    </AccountContentContainer>
  );
};

export default BookingDetailsContent;
