import React, { FC, useState, useEffect, useRef } from 'react';
import { useHistory } from 'react-router-dom';
import { useRecoilValue } from 'recoil'
import { roomQuantityState } from 'states/SearchBarStates/atoms';
import { selectedRooms } from 'states/CheckOutStates/atoms';
import StyledBadge from '../Badge';
import StyledButton from '../Button'
import { HeaderContainer, HotelTitle } from './style';
import { HeaderIcon } from 'assets/icons';
import { PersonIcon, NewLogoIcon } from 'assets/icons';
import HeaderMenu from 'components/HeaderMenu';
import { Icon } from 'components/Icon';
import { useAuth } from 'customHooks/useAuth';
import hotelLogo from 'assets/images/rook-logo.svg';
import { useGetClosestBookingInfo } from 'customHooks/useGetClosestBookingInfo';
import { styleSizes } from "constants/styleSizes";
import { useLocation } from 'react-router-dom';

const Header: FC = () => {

  const debug = false;
  
  const { validToken, userLogout } = useAuth();
  const [isActiveIcon, setActiveIcon] = useState(false);
  const [isSignedIn, setIsSignedIn] = useState(!!validToken)
  const history = useHistory();
  const roomQuantity = useRecoilValue(roomQuantityState)
  const persistSelectedRooms = useRecoilValue(selectedRooms)

  const [showBookNowBotton, setShowBookNowBotton] = useState(false);
  const [showAddGuestInfoBotton, setShowAddGuestInfoBotton] = useState(false);
  const [showAddGuestInfoBottonLink, setShowAddGuestInfoBottonLink] = useState('/');

  const { loading, bookingInfo:closestBookingInfo } = useGetClosestBookingInfo();
  const location = useLocation();

  // const componentRef = useRef<HTMLDivElement | null>(null);
  // const handleClickOutside = (event: MouseEvent) => {
  //   if (componentRef.current && !componentRef.current.contains(event.target as Node)) {
  //     setActiveIcon(false);
  //   }
  // };

  // useEffect(() => {
  //   document.addEventListener('mousedown', handleClickOutside);
  //   return () => {
  //     document.removeEventListener('mousedown', handleClickOutside);
  //   };
  // }, []);

  // const userLogout = () => {

  //   localStorage.clear()
  //   client.clearStore()
  //   // setValidToken(null)
  //   // clearSelectedRooms()
  //   // clearUserInfo()
  //   // clearPaymentInfo()
  //   console.log('Log out successfully!')
  //   history.push('/')
  // }


  useEffect(() => {
    const tabletBreakpointNumber = parseInt(styleSizes.tabletBreakpoint, 10);
  
    // Handler to manage UI updates on window resize
    const handleResize = () => {
      if ((window.innerWidth < tabletBreakpointNumber) && location.pathname == "/account/dashboard") {
        if (closestBookingInfo == null) {
          setShowBookNowBotton(true);
          setShowAddGuestInfoBotton(false);
          console.log ("closestBookingInfonull: ", closestBookingInfo);
        } else {
          setShowBookNowBotton(false);
          setShowAddGuestInfoBotton(false);
          if (closestBookingInfo.status === "INCOMPLETE") {
            setShowAddGuestInfoBotton(true);
            setShowBookNowBotton(false);
            setShowAddGuestInfoBottonLink(`/account/bookings/addGuestInfo/${closestBookingInfo.groupID}/1`);
          } else if (closestBookingInfo.status === "CHECKED_OUT") {
            setShowBookNowBotton(true);
            setShowAddGuestInfoBotton(false);
          }
        }
      } else {
        // Reset buttons if the window is larger than the tablet breakpoint
        setShowBookNowBotton(false);
        setShowAddGuestInfoBotton(false);
      }

 
      if (debug) {
        console.log ("closestBookingInfo: ", closestBookingInfo);
        console.log ("showBookNowBotton: ", showBookNowBotton);
        console.log ("showAddGuestInfoBotton: ", showAddGuestInfoBotton);
      }

    };
  
    // Call the handler on the initial render to set the correct state
    handleResize();
  
    // Add event listener to handle window resize
    window.addEventListener('resize', handleResize);
  
    // Cleanup the event listener on component unmount
    return () => {
      window.removeEventListener('resize', handleResize);
    };

  }, [closestBookingInfo]);
  

  useEffect(() => {
    setIsSignedIn(!!validToken)
  }, [validToken, setIsSignedIn])

  return (
    <HeaderContainer>
      <div className="innerContainer">
        <div
          className="navTitle"
          onClick={() => {
            history.push('/');
          }}>
          {/* <Icon
            width={42}
            height={31}
          >
            <NewLogoIcon />
          </Icon> */}
          {/* <HotelTitle>
            <span>ROOK</span> HOTEL
          </HotelTitle> */}
          <img src={hotelLogo} alt="hotelLogo" className="hotelLogo" />
        </div>
        <div className="HeaderContent">
          <div
            className="HeaderLink"
            onClick={() => {
              history.push('/about');
            }}
          >
            About Rook
          </div>


          {(!showBookNowBotton && !showAddGuestInfoBotton) ?
            <React.Fragment>
              <div
                className="HeaderLink"
                onClick={() => {
                  history.push('/discover');
                }}
              >
                Discover
              </div>
              <div
                className="HeaderLink"
                onClick={() => {
                  history.push('/contact');
                }}
              >
                Contact us
              </div>
            </React.Fragment>
            :
            null
          }



        </div>


        <div className="controlButtons">

          {showBookNowBotton ?
            <StyledButton
              className="bookNowButton"
              width="93px"
              fontSize={14}
              height="38px"
              onClick={() => {
                history.push('/');
              }}
            >
              Book Now
            </StyledButton>
            :
            null
          }

          {showAddGuestInfoBotton ?
            <StyledButton
              className="addGuestInfoButton"
              width="120px"
              fontSize={14}
              height="38px"
              onClick={() => {
                history.push(showAddGuestInfoBottonLink);
              }}
            >
              Add Guest Info
            </StyledButton>
            :
            null
          }



        {isSignedIn? (
          <StyledButton
          className="signInButton"
          width="63px"
          fontSize={14}
          height="38px"
          onClick={() => userLogout() }
        >
          Logout
        </StyledButton>

        ) : (
          <StyledButton
            className="signInButton"
            width="63px"
            fontSize={14}
            height="38px"
            onClick={() => {
              history.push('/signIn');
            }}
          >
            Sign in
          </StyledButton>
        )}
        
        </div>


        {/* <StyledBadge
          className="navBar"
          invisible = {Object.keys(persistSelectedRooms).length === 0}
          badgeContent={roomQuantity}
          onClick={() => {
            setActiveIcon(!isActiveIcon)
          }}
        >
          <Icon isActiveIcon={false}>
            <HeaderIcon />
          </Icon>
          <Icon
            omitted = {!isSignedIn}
            customerStyle
            activeIconStyle            
            isActiveIcon={isActiveIcon}
            style ={{marginRight: '-10px'}}
          >
            <PersonIcon />
          </Icon>
          {isActiveIcon? (
            <div className='menuContainer' ref={componentRef}>
              <HeaderMenu />
            </div>
          ) : null}
        </StyledBadge> */}
      </div>
    </HeaderContainer>
  );
};
export default Header;
