import React, { useEffect, useState } from 'react';
import BookingBanner from 'components/BookingBanner';
import make3 from 'assets/images/make-3.jpg';
import make4 from 'assets/images/make-4.jpg';
import { useGetClosestBookingInfo } from 'customHooks/useGetClosestBookingInfo';

const BookingBannerCompleteYourBooking: React.FC = ({

}) => {

    const { loading, bookingInfo: closestBookingInfo } = useGetClosestBookingInfo();
    const [currentBookingButtonURL, setCurrentBookingButtonURL] = useState('/');
    const [showBookingBanner, setShowBookingBanner] = useState(false);


    const debug = true;

    useEffect(() => {

        if (closestBookingInfo) {
            if (debug) {
                console.log("closestBookingInfo: ", closestBookingInfo);
            }

            if (closestBookingInfo.status === 'INCOMPLETE') {
                setCurrentBookingButtonURL('/account/bookings/addGuestInfo/' + closestBookingInfo.groupID + '/1');
                setShowBookingBanner(true);
            }

        } else {
            if (debug) {
                console.log("No bookings.");
            }
            setShowBookingBanner(false);
        }

    }, [closestBookingInfo]);


    if (!showBookingBanner || loading) return null;

    return (

        <BookingBanner
            buttonText="Add Guest Info "
            buttonUrl={currentBookingButtonURL}
            mainText="Complete Your Booking"
            secondaryText="Make a quicker getaway! Adding guest details will make check-in faster when you arrive."
            image1={make3}
            image2={make4}
            buttonWidth="153px"
        />
    );
};

export default BookingBannerCompleteYourBooking;