import styled from "styled-components";
import { colors } from "constants/colors";

export const NumberInputWithButtonsContainer = styled.div`
  display: flex;
  align-items: center;
  .MuiButton-root {
    color: white;
        font-weight: 700;
        font-size: 24px;
        line-height: 25px;
  }
  .MuiButton-root.Mui-disabled {
        background-color:${colors.greyButton};
        color: white;
        font-weight: 700;
        font-size: 24px;
    }

  .inputField {
    text-align: center; /* Center text horizontally */
    font-size: 16px; 
    font-weight: bold; 
    margin: 0 8px;
    display: flex; 
    align-items: center; /* Center content vertically */
    justify-content: center; /* Center content horizontally */
    height: 48px; /* Match the height of buttons */
    line-height: 48px; /* Ensure text aligns vertically within the height */
    padding: 0; /* Remove extra padding that might offset the text */
    input {
        padding-left: 18px;
    }

  }
`;