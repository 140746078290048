import { useState} from 'react';
import { useMutation} from '@apollo/client';
import { UPDATE_GUEST_INFORMATION} from 'graphql/service';

import {globalMessageState, globalErrorState} from 'states/CommonStates/atoms';
import { useRecoilState } from 'recoil';


/**
 * A custom hook for changing guest info
 */


export const useChangeGuestInfo = (onSuccess) => {

  const debug = true;
  


  const [messageState, setMessageState] = useRecoilState(globalMessageState);
  const [errorState, setErrorState] = useRecoilState(globalErrorState);

  const [isChangeGuestInfoChanged, setIsChangeGuestInfoChanged] = useState(false);


  const [updateGuestInformation] = useMutation(UPDATE_GUEST_INFORMATION, {
    onCompleted: (data) => {
      if (debug) {
          console.log ('useChangeGuestInfo data =', data);
      }

      setMessageState((prevState)=>({
        ...prevState,
        isVisible: true,
        text: "Guest information was successfully changed."
      }));

      // Callback onSuccess
      if (onSuccess) {
        onSuccess();
      }

    },
    onError: (error) => {
      const content = error.message;
      
      setErrorState((prevState)=>({
        ...prevState,
        isVisible: true,
        text: content
      }));

      if (debug) {
        console.log("useChangeGuestInfo error =", error);
      }
    },
  });




  const handleChangeGuestInfo = (bookingId, numberOfKeys, fullName, phoneNumber ) => {
    updateGuestInformation({
      variables: {
        input: {
            bookingId: bookingId,
            phoneNumber: phoneNumber, 
            numberOfKeys: numberOfKeys,
            fullName: fullName
        }
       },
    });
  };

  return {
    handleChangeGuestInfo
  }
}

