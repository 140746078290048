import {  Trips, Booking } from 'types/commonTypes';
import { useEffect } from 'react';
import { useLoadTrips } from 'customHooks/useLoadTrips';


export const useGetClosestBookingInfo = () => {
    
    const { trips, loading, refetch } = useLoadTrips()

    const debug = false;

    let nearestBooking: Booking | null = null;
    let nearesCheckedOutBooking: Booking | null = null;
    let nearestBookingGroupStatus: string = '';
    let nearestCheckedOutBookingGroupStatus: string = '';
    let nearestBookingGroupID: string = '';
    let nearestCheckedOutBookingGroupID: string = '';

    // Get the current date (today's date)
    const today = new Date();

// Refetch the data every time the hook is called
// useEffect(() => {
//   refetch(); // Trigger the refetch when the hook is called
// }, [refetch]);

    // Iterate through all groups in trips
    for (const group of Object.values(trips)) {


      // Initial status:
      let currentGroupBookingStatus = "CONFIRMED";

      let checkedOutStatusCounter = 0;
      let expiterdStatusCounter = 0;
      let cancelledStatusCounter = 0;
      let incompliteBookings = 0;

      group.bookings.forEach(currntBooking => {

        switch (currntBooking.status) {
          case "CHECKED_OUT":
            checkedOutStatusCounter++;
            break;
          case "CHECKED_IN":
            // If one CHECKED_IN -> group CHECKED_IN
            currentGroupBookingStatus = "CHECKED_IN";
            break;
          case "EXPIRED":
            expiterdStatusCounter++;
            break;
          case "CANCELLED":
            cancelledStatusCounter++;
            break;
          case "PENDING":

            if (!(currntBooking.fullName && currntBooking.phoneNumber)) {
              incompliteBookings++;
            }

            break;
        }

        // If everyone CHECKED_OUT -> group CHECKED_OUT
        if (checkedOutStatusCounter === group.bookings.length) {
          currentGroupBookingStatus = "CHECKED_OUT";
        }

        // If everyone EXPIRED -> group EXPIRED
        if (expiterdStatusCounter === group.bookings.length) {
          currentGroupBookingStatus = "EXPIRED";
        }

        // If everyone CANCELLED -> group CANCELLED
        if (cancelledStatusCounter === group.bookings.length) {
          currentGroupBookingStatus = "CANCELLED";
        }

        // If only CANCELLED and EXPIRED in group -> group EXPIRED

        // Vadim: If one booking in grop is CANCELLED and one is EXPIRED what group status will be?
        // Shamim: I think we should use EXPIRED for this one since we will still charge the guest.
        if (
          (cancelledStatusCounter + expiterdStatusCounter === group.bookings.length) &&
          cancelledStatusCounter > 0 &&
          expiterdStatusCounter > 0
        ) {
          currentGroupBookingStatus = "EXPIRED";
        }


      });

      // If thre is INCOMPLETE 1 or more bookings -> group INCOMPLETE
      if (currentGroupBookingStatus === 'CONFIRMED' && incompliteBookings > 0) {
        currentGroupBookingStatus = 'INCOMPLETE';
      }


      // Determinating group status END

      for (const booking of group.bookings) {

        const checkOutDate = new Date(booking.checkOutDate);


        if (debug) {
          console.log ('checkOutDate = ', checkOutDate);
          console.log ('today = ', today);
        }

        // if (checkOutDate < today) {
        //   console.log ("Skip the booking...");
        //   continue; // Skip the booking if checkOut date is in the past
        // }


        if (
          (currentGroupBookingStatus === "CONFIRMED" || currentGroupBookingStatus === "INCOMPLETE" || currentGroupBookingStatus === "CHECKED_IN") &&
          (!nearestBooking || new Date(booking.checkInDate) < new Date(nearestBooking.checkInDate)) &&
          (checkOutDate > today) // Skip the booking if checkOut date is in the past
        ) {
          nearestBooking = booking; // Assign booking to nearestBooking
          nearestBookingGroupStatus = currentGroupBookingStatus; // Assign booking to nearestBooking
          nearestBookingGroupID = group.groupId;
        }

        if (
          (currentGroupBookingStatus === "CHECKED_OUT") &&
          (!nearesCheckedOutBooking || new Date(booking.checkInDate) < new Date(nearesCheckedOutBooking.checkInDate))
        ) {
          nearesCheckedOutBooking = booking; // Assign booking to nearesCheckedOutBooking
          nearestCheckedOutBookingGroupStatus = currentGroupBookingStatus; // Assign booking to nearesCheckedOutBooking
          nearestCheckedOutBookingGroupID = group.groupId;
        }

      }
    }

    if (debug) {
      console.log ("nearestBooking: ", nearestBooking);
      console.log ("nearesCheckedOutBooking: ", nearesCheckedOutBooking);
    }

    // If no suitable booking, but there is CheckedOutBooking, show CheckedOutBooking
    if (!nearestBooking && nearesCheckedOutBooking) {
      nearestBooking = nearesCheckedOutBooking;
      nearestBookingGroupStatus = nearestCheckedOutBookingGroupStatus;
      nearestBookingGroupID = nearestCheckedOutBookingGroupID;

    }
    
    // If no suitable booking is found, return null
    if (!nearestBooking) {
        return { loading, bookingInfo: null };
    }


    // Safely access properties of the nearestBooking
    return {
        loading,
        bookingInfo: {
          status: nearestBookingGroupStatus,
          checkIn: nearestBooking.checkInDate
            ? new Intl.DateTimeFormat('en-GB', {
              weekday: 'short',
              day: '2-digit',
              month: 'short',
              hour: '2-digit',
              minute: '2-digit',
              hour12: true
            }).format(new Date(nearestBooking.checkInDate))
            : "Unknown Date",
          checkOut: nearestBooking.checkOutDate
            ? new Intl.DateTimeFormat('en-GB', {
              weekday: 'short',
              day: '2-digit',
              month: 'short',
              hour: '2-digit',
              minute: '2-digit',
              hour12: true
            }).format(new Date(nearestBooking.checkOutDate))
            : "Unknown Date",
          groupID: nearestBookingGroupID
        }
      };
    };